<template lang="">
    <Intro bgUrl="background-image: url('images/intro-rgpddpoex.jpg');">
      <div class="title">
          <h3>By SecureLabs</h3>
      </div>
      <div class="content">
          <h4>Cartographie des traitements de données</h4>
      </div>
      <div class="action">
          <a href="#choiceUs">Découvrez maintenant</a>
      </div>
    </Intro>
    <main>
        <div class="page-content">

                <div class="container">
                    <div id="first" class="container mt-6" style="height: 80vh;">
                        <div class="heading pt-4">
                        
                            <h3 class="mb-5 title-color-primary" style="font-weight: 600;">Cartographie des traitements de données</h3>
           
                         
                               
                            <p class="text-big"> 
                                Le Règlement Général sur la Protection des Données (RGPD) exige la tenue d’un registre des traitements de données. Ce registre intègre l’ensemble de la documentation interne portant sur les traitements de données et permet d’avoir une vision globalisée sur les écarts de conformité aux obligations réglementaires.<br><br>
SECURELABS vous déleste de cette étape pénible en construisant, pour vous, le registre des traitements de données à caractère personnel. Outre notre expertise en la matière, nous vous apportons une véritable valeur ajoutée via l’utilisation de notre outil . <br><br>
La cartographie des traitements sera donc réalisée via une série d’entretiens avec chaque responsable opérationnel dans le but d’identifier les finalités du traitement, les catégories de données traitées et les personnes concernées, les destinataires et les durées de conservation. <br><br>
A l’issue de ces entretiens, SECURELABS vous livrera votre registre des traitements données (cartographie) et un plan d’action correctif des écarts de conformité.


                            </p>

                        </div>
                       
                    <!-- first -->
                    </div>
                    
                </div><!-- End .container -->
            </div><!-- End .page-content -->
    </main>
</template>
<script>
import Intro from '@/components/Intro'
export default {
    components:{
        Intro
    }
}
</script>
<style lang="">
    
</style>
