<template lang="">
    <div class="background mb-5" style="min-height:81vh;background-image: url('/images/Formation/Formargpd.jpg');" >
      
      <div class="slider"  style="min-height:81vh;">
          <div class="intro col-8 mx-auto">
            <div class="title">
                  <h3>By Securelabs</h3>
              </div>
              <div class="content">
                  <h4>Formation RGPD</h4>
              </div>
              <div class="action">
                  <a href="#contenu">Découvrez maintenant</a>
              </div>
          </div>
      </div>
  </div>
  <main>
    <span class="  title-color-primary h3">Formation aux bonnes pratiques de protection des données personnelles</span>
      <div class="row mt-5">
        <div class="col-12 col-md-10 mx-auto">
          <div class="nav-align-top mb-4">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <button type="button" class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Obj" aria-controls="Partie1Obj" aria-selected="true">
                  <img class=" rounded" src="/images/Objectif2.png" height="30" width="30" alt="">
                </button>
              </li>
              <li class="nav-item">
                <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Prog" aria-controls="Partie1Prog" aria-selected="false">
                  <img class=" rounded" src="/images/Programme.jpg" height="30" width="30" alt="">
                </button>
              </li>
              <li class="nav-item">
                <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Prati" aria-controls="Partie1Prati" aria-selected="false">
                  <img class=" rounded" src="/images/Pratique.jpg" height="30" width="30" alt="">
                </button>
              </li>
              <li class="nav-item">
                <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Plus" aria-controls="Partie1Plus" aria-selected="false">
                  <img class=" rounded" src="/images/plus.jpg" height="50" width="50" alt="">
                </button>
              </li>
            </ul>
            <div class="tab-content mt-3">
              <div class="tab-pane fade active show text-left" id="Partie1Obj" role="tabpanel">
                <p class="text-big">A l'issue de cette formation, le participant sera en capacité de :</p>
                <ul class="list-box">
            <li>COMPRENDRE l’évolution juridique de la protection des données personnelles.</li>
            <li>ACQUÉRIR des connaissances solides relatives aux définitions et aux principes du RGPD.</li>
            <li>APPRÉHENDER la fonction et les missions du délégué à la protection des données.</li>
            <li>ACCROÎTRE ces compétences opérationnelles à l’aide de mises en situation.</li>
          </ul>
              </div>
              <div class="tab-pane fade text-left" id="Partie1Prog" role="tabpanel">
                  <ul class="mt-1 list-box">
                    <li>Le cadre juridique du RGPD.</li>
                    <li>Les Définitions.</li>
                    <li>La CNIL - Autorité de Régulation</li>
                    <li>Les acteurs du RGPD.</li>
                    <li>Les Principes du RGPD.</li>
                    <li>Les Obligations du RGPD.</li>
                    <li>Le Droit des personnes.</li>
                    <li>Le Délégué à la Protection des Données.</li>
                    <li>Les Formalités.</li>
                    <li>Les Transferts de données.</li>
                  </ul>
              </div>
              <div class="tab-pane fade text-left" id="Partie1Prati" role="tabpanel">
                <h5 class="text-info text-start p-3 ">Méthodes pédagogiques</h5>
                  <ul class="mt-1 list-box">
                    <li>Cas pratiques (registre des traitements, applications des principes, exercice des droits, transferts des données,etc...).</li>
                    <li>Exemples et retours d’expériences.</li>
                    <li>Actualité juridique</li>
                    <li>Mise en contexte des éléments théoriques.</li>
                  </ul>
                  <h5 class="text-info text-start p-3 ">Moyens pédagogiques</h5>
                  
                  <ul class="mt-1 list-box">
                    <li>Support de formation.</li>
                    <li>Documentation juridique.</li>
                  </ul>
                  <h5 class="text-info text-start p-3 ">Modalités d'évaluation</h5>
                  
                  <ul class="mt-1 list-box">
                    <li>Contrôle continu pendant la formation.</li>
                    <li>Questionnaires à Choix Multiples (QCM) validant les différents modules.</li>
                  </ul>
              </div>
              <div class="tab-pane fade text-left" id="Partie1Plus" role="tabpanel">
                <ul class="mt-1 list-box">
                  <li>Cours animés par des juristes experts en protection des données et certifiés DPO et ISO 27001.</li>
                  <li>Formateurs certifiés PECB.</li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <span class=" title-color-primary h3">Formation Délégué à la protection des données</span>
      </div>
<div class="row mt-5">
  <div class="col-12 col-md-10 mx-auto">
    <div class="nav-align-top mb-4">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <button type="button" class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#Partie2Obj" aria-controls="Partie2Obj" aria-selected="true">
            <img class=" rounded" src="/images/Objectif2.png" height="30" width="30" alt="">
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie2Prog" aria-controls="Partie2Prog" aria-selected="false">
            <img class=" rounded" src="/images/Programme.jpg" height="30" width="30" alt="">
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie2Prati" aria-controls="Partie2Prati" aria-selected="false">
            <img class=" rounded" src="/images/Pratique.jpg" height="30" width="30" alt="">
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie2Plus" aria-controls="Partie2Plus" aria-selected="false">
            <img class=" rounded" src="/images/plus.jpg" height="50" width="50" alt="">
          </button>
        </li>
      </ul>
      <div class="tab-content mt-3">
        <div class="tab-pane fade active show text-left" id="Partie2Obj" role="tabpanel">
          <p class="text-big text-start">A l'issue de cette formation, le participant sera en capacité de :</p>
          <ul class="list-box">
            <li>CONNAÎTRE les missions du Data Protection Officer (DPO).</li>
            <li>ACQUERIR les connaissances juridiques, technique et organisationnelles nécessaires à l'exercice de ces fonctions.</li>
            <li>APPRÉHENDER les démarches et outils nécessaires au maniement des règles en matière de protection des données.</li>
            <li>DISPOSER de l’ensemble des connaissances utiles à la réussite à l’examen de certification.</li>
          </ul>
        </div>
        <div class="tab-pane fade text-left" id="Partie2Prog" role="tabpanel">
          <h5 class="text-info text-start p-3 ">Introduction au RGPD et initialisation de la conformité au RGPD </h5>
      
      <ul class="mt-1 list-box list-box">
        <li>Introduction au RGPD.</li>
        <li>Principes fondamentaux du RGPD.</li>
        <li>Initialisation de la mise en oeuvre du RGPD.</li>
        <li>Comprendre l'organisation et clarifier les objectifs de la protection des données.</li>
      </ul>
  
      <h5 class="text-info text-start p-3 ">Planifier la mise en oeuvre du RGPD</h5>
      
      <ul class="mt-1 list-box">
        <li>Leadership et approbation du projet de conformité RGPD.</li>
        <li>Politique de protection des données.</li>
        <li>Définition de la structure organisationnelle de la protection des données.</li>
      </ul>
      <h5 class="text-info text-start p-3 ">Déployer le RGPD </h5>
      
      <ul class="mt-1 list-box">
        <li>Étude d’impact à la vie privée (EIVP).</li>
        <li>Contrôles, processus, politiques, procédures et gestion de la documentation.</li>
        <li>Plan de communication.</li>
        <li>Plan de formation et de sensibilisation.</li>
      </ul>
      <h5 class="text-info text-start p-3 ">Suivi et amélioration continue de la conformité au RGPD </h5>
      
      <ul class="mt-1 list-box">
        <li>-Gestion des incidents et des violations de données.</li>
        <li>-Audit interne.</li>
        <li>-Actions correctives.</li>
          </ul>
          <h5 class="text-info text-start p-3 ">Passage de la certification</h5>
      
      <ul class="mt-1  list-box">
        <li>Prix et passage de la certification inclus dans le tarif .</li>
        <li>Examen de 3h.</li>
      </ul>
        </div>
        <div class="tab-pane fade text-left" id="Partie2Prati" role="tabpanel">
                    <h5 class="text-info text-start p-3 ">Méthodes pédagogiques</h5>
            <ul class="mt-1  list-box">
              <li>Cas pratiques (registre des traitements, applications des principes, exercice des droits, transferts des données,etc...).</li>
              <li>Exemples et retours d’expériences.</li>
              <li>Actualité juridique</li>
              <li>Mise en contexte des éléments théoriques.</li>
            </ul>
            <h5 class="text-info text-start p-3 ">Moyens pédagogiques</h5>
            
            <ul class="mt-1  list-box">
              <li>Support de formation.</li>
              <li>Documentation juridique.</li>
            </ul>
            <h5 class="text-info text-start p-3 ">Modalités d'évaluation</h5>
            
            <p class="text-big text-start">L’évaluation des connaissances s’effectue via:</p>
            <ul class="mt-1 list-box">
              <li>Contrôle continu pendant la formation.</li>
              <li>Questionnaires à Choix Multiples (QCM) validant les différents modules.</li>
            </ul>
        </div>
        <div class="tab-pane fade text-left" id="Partie2Plus" role="tabpanel">
          <ul class="mt-1 list-box">
            <li>Cours animés par des juristes experts en protection des données et certifiés DPO et ISO 27001.</li>
            <li>Formateurs certifiés PECB.</li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</div>
    </main>         
    </template>
    <script>
    import Intro from '@/components/Intro'
    export default {
      components:{
            Intro
        }  
    }
    </script>
    <style lang="">
        
    </style>
    