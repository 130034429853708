<template lang="">
    <Intro bgUrl="background-image: url('images/contactUs.jpg');">
             <!-- we will get this using -->
            <div class="title">
                <h3>Conditions générales d’utilisation</h3>
            </div>
            <div class="content">
                <h4>by Securelabs</h4>
            </div>
            <div class="action">
                <a href="#contact-form">Découvrir</a>
            </div>
    </Intro>

    <main class="m-5">
        <div class="itemCondi">
            <strong class="text-primary h3">Conditions Générales d’Utilisation</strong>

            <p class="activity-listing-subtitle">
        Conditions Générales d’Utilisation du site <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a> 
        Les présentes Conditions Générales d’Utilisation (ci-après dénommées CGU) ont pour objet de définir
        les modalités et conditions dans lesquelles, SECURELABS met à la disposition des utilisateurs 
        (ci-après dénommé les Utilisateurs) du site <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a> (ci-après dénommé le Site)
        les services associés et la manière par laquelle les Utilisateurs accèdent au Site et 
        utilisent ses services. Toute connexion au Site est subordonnée au respect des CGU. L’Utilisateur 
        reconnaît à ce titre que le simple accès au Site implique l’acceptation de l’ensemble des conditions décrites ci-après.
    </p>
        </div>
    <div class="itemCondi">
        <strong class="text-primary h3">Accès au Site</strong>
    <p class="activity-listing-subtitle">L’Utilisateur du Site reconnaît avoir vérifié que la configuration informatique qu’il utilise ne contient aucun virus et qu’elle 
        est en parfait état de fonctionnement. Il est rappelé que le secret des correspondances n’est pas garanti sur le réseau Internet
         et qu’il appartient à chaque Utilisateur d’Internet de prendre toutes les mesures appropriées de façon à protéger ses propres
          données et/ou logiciels de la contamination d’éventuels virus circulant sur Internet. 
SECURELABS ne saurait être tenue pour responsable d’éventuels dommages, directs ou indirects, pouvant découler de l’accès, 
de l’utilisation ou de téléchargement de contenu via le Site, notamment, un dommage ou virus qui pourrait infecter l’ordinateur de l’Utilisateur
 ou tout autre matériel. SECURELABS s’efforce de permettre l’accès au Site 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure 
 ou d’un événement hors de son contrôle, et sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon 
 fonctionnement du Site et des services. 
Par conséquent, SECURELABS ne peut garantir une disponibilité du Site et/ou des services,
 une fiabilité des transmissions et des performances en termes de temps de réponse ou de qualité.
  Il n’est prévu aucune assistance technique vis-à-vis de l’Utilisateur que ce soit par des moyens électronique ou téléphonique.
  La responsabilité de SECURELABS ne saurait être engagée en cas d’impossibilité d’accès à ce Site et/ou d’utilisation des services. 
  Par ailleurs, SECURELABS peut être amenée à interrompre le Site ou une partie des services, à tout moment sans préavis, le tout sans
  droit à indemnités. L’Utilisateur reconnaît et accepte que SECURELABS ne soit pas responsable des interruptions, et des conséquences 
  qui peuvent en découler pour l’Utilisateur ou tout tiers.
</p>
    </div>
<div class="itemCondi">
    <strong class="text-primary h3">Contenu du Site</strong>
<p class="activity-listing-subtitle">
    Les informations et/ou documents figurant sur le Site et/ou accessibles par le Site proviennent de sources considérées comme étant fiables. Toutefois, ces informations et/ou documents sont susceptibles de contenir des inexactitudes techniques et des erreurs typographiques. 
SECURELABS se réserve le droit de les corriger, dès que ces erreurs sont portées à sa connaissance. Il est fortement recommandé de vérifier l’exactitude et la pertinence des informations et/ou documents mis à disposition sur le Site. Les informations et/ou documents disponibles sur le Site sont susceptibles d’être modifiés à tout moment, et peuvent avoir fait l’objet de mises à jour. En particulier, ils peuvent avoir fait l’objet d’une mise à jour entre le moment de leur téléchargement et celui où l’Utilisateur en prend connaissance. L’utilisation des informations et/ou documents disponibles sur le Site se fait sous l’entière et seule responsabilité de l’Utilisateur, qui assume la totalité des conséquences pouvant en découler, sans que la responsabilité de SECURELABS puisse être recherchée. SECURELABS ne pourra en aucun cas être tenue responsable de tout dommage de quelque nature qu’il soit résultant de l’interprétation ou de l’utilisation des informations et/ou documents disponibles sur le Site.
</p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Propriété intellectuelle</strong>
<p class="activity-listing-subtitle">
    La structure générale du Site et son contenu sont la propriété exclusive de SECURELABS, et font l’objet d’une protection légale au titre de la propriété intellectuelle (droits d’auteur, bases de données, logiciels, marques, etc.). Toute reproduction, représentation ou adaptation totale ou partielle du Site par une personne physique ou morale, par quelque procédé que ce soit, sans l’autorisation expresse de SECURELABS est interdite et constituerait un acte de contrefaçon sanctionné notamment par les articles L. 335-2 et suivants du Code de la propriété intellectuelle. Toute réutilisation ou extraction totale ou partielle, des bases de données du Site par une personne physique ou morale, par quelque procédé que ce soit, est interdite et constituerait un acte de contrefaçon sanctionné par les articles L. 343-1 et suivants du Code de la propriété intellectuelle. Toute reproduction totale ou partielle des marques et logos de SECURELABS réalisées sans son autorisation expresse est interdite en application des articles L. 713-2 et suivants du Code de la propriété intellectuelle.
</p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Liens hypertextes</strong>
<p class="activity-listing-subtitle">
    Le Site peut contenir des liens hypertextes ciblant d’autres sites présents sur le réseau Internet. Les liens vers ces autres ressources vous font quitter le Site. Il est possible de créer un lien vers la page de présentation de ce Site sans autorisation expresse de SECURELABS, à condition que le lien affiche le Site dans une nouvelle fenêtre du navigateur. Il est néanmoins demandé d’en informer préalablement SECURELABS, qui peut à tout moment demander la suppression du lien.
    </p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Protection des données à caractère personnel</strong>
<p class="activity-listing-subtitle">
    Conformément à la loi Informatique et Libertés n°78-17 du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données à caractère personnel (ci-après « RGPD ») n° 2016/679/UE du 27 avril 2016, SECURELABS, en qualité de responsable de traitement, garantit le respect de la vie privée des Utilisateurs du Site. 
</p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Modification des conditions d’utilisation</strong>
<p class="activity-listing-subtitle">
    SECURELABS se réserve la possibilité de modifier, à tout moment et sans préavis, les présentes conditions générales d’utilisation afin de les adapter aux évolutions du Site et/ou de son exploitation.
    </p>
</div>
    <div class="itemCondi">
        <strong class="text-primary h3">Preuve</strong>
<p class="activity-listing-subtitle">
    Les registres des systèmes informatiques du Site sont conservés sur un support fidèle, durable et sécurisé par SECURELABS ou son prestataire dans le respect des règles de l’art. En cas de litige, les données de ces registres feront foi, notamment en ce qui concerne la preuve d’envoi de formulaire d’inscription ou de souscription à des produits ou services, de téléchargements de contenu, de publication de contenu, d’échange de courriers électroniques, etc.
    </p>
    </div>

   <div class="itemCondi">
    <strong class="text-primary h3">Loi applicable et juridiction compétente</strong>
<p class="activity-listing-subtitle">
    Les présentes Conditions Générales d’Utilisation et le contenu du Site sont régis par la loi française. Les parties conviennent que les différends qui viendraient à se produire à la suite ou à l’occasion des présentes, faute de pouvoir être réglés à l’amiable, seront soumis aux juridictions françaises compétentes.
    <div class="mt-3">© Securelabs – tous droits réservés</div>    
</p>
    
   </div>
    </main>

</template>
<script>
import Intro from '@/components/Intro'
    export default {
      components:{
            Intro
        }  
    }
</script>
<style>
.itemCondi{
    margin-top: 30px;
}
</style>