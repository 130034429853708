<template lang="">
   <Intro bgUrl="background-image: url('images/contactUs.jpg');">
             <!-- we will get this using -->
            <div class="title">
                <h3>Mentions légales</h3>
            </div>
            <div class="content">
                <h4>by Securelabs</h4>
            </div>
            <div class="action">
                <a href="#contact-form">Découvrir</a>
            </div>
    </Intro>
    <main class="m-5">
        <div class="itemCondi">
            <p class="activity-listing-subtitle">
                En vous connectant sur ce site, vous acceptez, sans réserve, les présentes modalités.
Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>   sont :
    </p>
        </div>
    <div class="itemCondi">
        <strong class="text-primary h3">Editeur</strong>
    <p class="activity-listing-subtitle">SECURELABS <br>
Numéro de SIRET : 89522307100013 <br>
Numéro de SIREN : 895223071 <br>
Code APE 6202A <br>
Numéro de TVA intra-communautaire : FR 58895223071 <br>
Directeur de la publication : Soufiane ZAHID, président de SECURELABS <br>
Téléphone : +33 (0)6 50 72 98 13 <br>
Email : soufiane.zahid@securelabs-cybersecurite.com <br>
Site web :  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>   <br>
</p>
    </div>
<div class="itemCondi">
    <strong class="text-primary h3">Identité de l’hébergeur</strong>
<p class="activity-listing-subtitle">
Le site  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>   est hébergé chez <a href="https://www.ovh.com" target="_blanck">OVHCloud</a>  <br>
Adresse : Lille, Hauts-de-France, France <br>
Téléphone : +33 (0) 6.50.72.98.13 <br>
Site web :  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>   <br>
</p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Liens hypertextes</strong>
<p class="activity-listing-subtitle">
    Le site internet ne peut offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. SECURELABS ne dispose d’aucun moyen pour contrôler les sites en connexion avec son/ses site(s) internet.
SECURELABS ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation.
</p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Services fournis</strong>
<p class="activity-listing-subtitle">
    L’ensemble des activités de la société ainsi que ses informations sont présentés sur notre site  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>  
SECURELABS s’efforce de fournir sur le site  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>   des informations aussi précises que possible. Les renseignements figurant sur le site  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>    ne sont pas exhaustifs et les photos non contractuelles.
Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, toutes les informations indiquées sur le site  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>   sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.    </p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Limitations contractuelles sur les données</strong>
<p class="activity-listing-subtitle">
    Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions.
Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par courriel, à l’adresse soufiane.zahid@securelabs-cybersecurite.com, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).
Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au téléchargement.
De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour. Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de SECURELABS.
</p>
</div>
<div class="itemCondi">
    <strong class="text-primary h3">Crédits images</strong>
<p class="activity-listing-subtitle">
    Certaines icônes sont l’œuvre de créateurs sur https://www.flaticon.com/.
</p>
<div class="row mt-4 pb-5">                
                    <div id="carouselExampleDark" class="carousel carousel-dark slide col-12 col-md-10 col-lg-8 mx-auto "  data-bs-ride="carousel">
                        
                        <div class="carousel-inner d-flex align-items-center" style="height:50vh;">
                          <div class="carousel-item"  data-bs-interval="2000" v-for="(icon,index) in Icons" :class="{ 'active': index === 0  }">
                            <img class="mx-auto" style="width:25rem;"  :src="icon.image" alt="">
                          </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                      </div>
</div>
</div>
    <div class="itemCondi">
        <strong class="text-primary h3">Propriété intellectuelle</strong>
<p class="activity-listing-subtitle">
    Tout le contenu du présent site  <a href="https://www.secure-labs.fr/">https://www.secure-labs.fr/</a>  , incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.
Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de SECURELABS. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des contenus copiés pourraient intenter une action en justice à votre encontre. <br>
<div class="mt-3">© Securelabs – tous droits réservés</div>
</p>
    </div>
    </main>
</template>
<script>
import Intro from '@/components/Intro'
    export default {
      components:{
            Intro
        },
    data() {
        return {
            
            Icons:[
                {
                    id:1,
                    image:"images/home/reality.png",
                },
                {
                    id:2,
                    image:"images/home/pragmatique.png",
                },
                {
                    id:3,
                    image:"images/cyber/auditOrganisationel/organisationnel.png",
                },
                {
                    id:4,
                    image:"images/cyber/auditOrganisationel/technique.png",
                },
                {
                    id:5,
                    image:"images/cyber/auditOrganisationel/physique.png",
                },
                {
                    id:6,
                    image:"images/cyber/analyseRisque/1.png",
                },
                {
                    id:7,
                    image:"images/cyber/analyseRisque/2.png",
                },
                {
                    id:8,
                    image:"images/cyber/gestionRisque/identify.png",
                },
                {
                    id:9,
                    image:"images/cyber/gestionRisque/presentation.png",
                },
                {
                    id:10,
                    image:"images/cyber/gestionRisque/presentation 2.png",
                },
                {
                    id:11,
                    image:"images/cyber/repriseActivity/tree-recovery.png",
                },
                {
                    id:12,
                    image:"images/cyber/repriseActivity/workflow.png",
                },
                {
                    id:13,
                    image:"images/cyber/rssi/guarantor.png",
                },
                {
                    id:14,
                    image:"images/cyber/rssi/mission.png",
                },
                {
                    id:15,
                    image:"images/cyber/rssi/strategy.png",
                },
                {
                    id:16,
                    image:"images/cyber/rssi/online-learning.png",
                },
                {
                    id:17,
                    image:"images/cyber/rssi/notification.png",
                },
                {
                    id:18,
                    image:"images/cyber/rssi/report.png",
                },
                {
                    id:19,
                    image:"images/cyber/rssi/dashboard.png",
                },
                {
                    id:20,
                    image:"images/cyber/rssi/document.png",
                },
                {
                    id:21,
                    image:"images/cyber/rssi/planning.png",
                },
                {
                    id:22,
                    image:"images/cyber/rssi/interaction.png",
                },
                {
                    id:23,
                    image:"images/cyber/rssi/documents.png",
                },
                {
                    id:24,
                    image:"images/cyber/rssi/implementation.png",
                },{
                    id:25,
                    image:"images/cyber/rssi/interactions.png",
                },{
                    id:26,
                    image:"images/cyber/rssi/protocols.png",
                },{
                    id:27,
                    image:"images/cyber/certification/certificate.png",
                },{
                    id:28,
                    image:"images/cyber/certification/medal.png",
                },{
                    id:29,
                    image:"images/rgpd/audit/1.png",
                },{
                    id:30,
                    image:"images/rgpd/audit/2.png",
                },{
                    id:31,
                    image:"images/rgpd/audit/3.png",
                },{
                    id:32,
                    image:"images/rgpd/dpoexmu/1.png",
                },{
                    id:33,
                    image:"images/rgpd/dpoexmu/2.png",
                },{
                    id:34,
                    image:"images/rgpd/dpoexmu/3.png",
                },{
                    id:35,
                    image:"images/rgpd/dpoexmu/4.png",
                }
            ]
        }
    }
    }
</script>
<style>
    .itemCondi{
    margin-top: 30px;
}
</style>