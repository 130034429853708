<template lang="">

<Intro bgUrl="background-image: url('/images/intro-rgpdaccomp.jpg');" class="mb-5">
               <!-- we will get this using -->
              <div class="title">
                  <h3>By securelabs</h3>
              </div>
              <div class="content">
                  <h4>Formation cybersécurité</h4>
              </div>
              <div class="action">
                  <a href="#contenu">Découvrez maintenant</a>
              </div>
  </Intro>
    <main>
      <span class="  title-color-primary h3">Formation gestion des risques IT et Système de Management de la Sécurité de l’Information</span>
      <div class="row mt-5">
        <div class="col-12 col-md-10 mx-auto">
          <div class="nav-align-top mb-4">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <button type="button" class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Obj" aria-controls="Partie1Obj" aria-selected="true">
                  <img class=" rounded" src="/images/Objectif2.png" height="30" width="30" alt="">
                </button>
              </li>
              <li class="nav-item">
                <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Prog" aria-controls="Partie1Prog" aria-selected="false">
                  <img class=" rounded" src="/images/Programme.jpg" height="30" width="30" alt="">
                </button>
              </li>
              <li class="nav-item">
                <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Prati" aria-controls="Partie1Prati" aria-selected="false">
                  <img class=" rounded" src="/images/Pratique.jpg" height="30" width="30" alt="">
                </button>
              </li>
              <li class="nav-item">
                <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie1Plus" aria-controls="Partie1Plus" aria-selected="false">
                  <img class=" rounded" src="/images/plus.jpg" height="50" width="50" alt="">
                </button>
              </li>
            </ul>
            <div class="tab-content mt-3">
              <div class="tab-pane fade active show text-left " id="Partie1Obj" role="tabpanel">
                <p class="text-big text-start">A l'issue de cette formation, le participant sera en capacité de :</p>
                <ul class="list-box">
                          <li>IDENTIFIER les risques associés aux TIC</li>
                          <li>ADOPTER les meilleures pratiques de gestion de la sécurité de l'information</li>
                          <li>MAÎTRISER les fondamentaux des standards du marché comme ISO 27001 ou ISO27005</li>
                          <li>METTRE EN PLACE un système de management de la sécurité</li>
                </ul>
              </div>
              <div class="tab-pane fade text-left" id="Partie1Prog" role="tabpanel">
                <h5 class="text-info text-start p-3 ">Gouvernance de la sécurité de l’information</h5>
          
                <ul class="mt-1 list-box">
                  <li>Définition, périmètre, rôles et responsabilités.</li>
                  <li>Gestion des partenaires et fournisseurs tiers.</li>
                  <li>Gestion stratégique de la sécurité de l’information</li>
                </ul>
                <h5 class="text-info text-start p-3 ">Gestion des risques et conformité</h5>
                
                <ul class="mt-1 list-box">
                  <li>Introduction.</li>
                  <li>Présentation des principales normes et référentiels (ISO 27005,....).</li>
                  <li>Définition du contexte, méthodes de gestion des risques (identification, classification, analyse, réponse et plan de mitigation, revue post implémentation et risques résiduels).</li>
                </ul>
                <h5 class="text-info text-start p-3 ">Introduction aux Systèmes de Management de la Sécurité de l’Information (SMSI) tel que définis par l’ISO 27001.</h5>
                
                <ul class="mt-1 list-box">
                  <li>Introduction.</li>
                  <li>Démarche de mise en œuvre d'un SMSI.</li>
                  <li>Audit interne d'un SMSI</li>
                  <li>Audit externe d'un SMSI</li>
                </ul>
              </div>
              <div class="tab-pane fade text-left" id="Partie1Prati" role="tabpanel">
                <h5 class="text-info text-start p-3 ">Méthodes pédagogiques</h5>
                <ul class="mt-1 list-box">
                  <li>Apports théoriques.</li>
                  <li>Cas pratiques.</li>
                  <li>Exemples et retours d’expériences</li>
                </ul>
                <h5 class="text-info text-start p-3 ">Moyens pédagogiques</h5>
                
                <ul class="mt-1 list-box">
                  <li>Disposer d'un ordinateur portable ou d'une tablette pour la transmission des supports de formation ainsi que des différents livrables nécessaires.</li>
                </ul>
                <h5 class="text-info text-start p-3 ">Modalités d'évaluation.</h5>
                
                <ul class="mt-1 list-box">
                  <li>Contrôle continu pendant la formation.</li>
                  <li>Questionnaires à Choix Multiples (QCM) validant les différents modules.</li>
                </ul>
              </div>
              <div class="tab-pane fade text-left" id="Partie1Plus" role="tabpanel">
                <ul class="mt-1 list-box">
                  <li>Des cas pratiques.</li>
                  <li>Des retours d'expériences.</li>
                  <li>Cours animés par des consultants experts en cybersécurité et certifiés ISO27001</li>
                  <li>Formateurs certifiés PECB.</li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
<div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="activity-item">
                <div class="row">
                    <a href="#" data-role="activity-box-link">
                        <div class="col-lg-12 p-0">
                            <!-- activity Image, Name & Subtitle (everyone) -->
                            <div class="activity-box-icon-container">
                                <img class="activity-box-icon" src="/images/certifiante.jpg">
                            </div>
                            <div class="activity-item-title" role="heading" aria-level="2">
                                Certifiante
                            </div>
                        </div>
                    </a>
                    <div class="activity-listing-subtitle text-left">
                      <ul  style="padding-left: 15%;" class="mt-3 list-box">
                        <li>5 jours de formation de préparation à l’examen de certification ISO27001</li>
                        <li>Délivrance de la certification ISO27001 par un organisme de certification accrédité</li>
                      </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="activity-item">
              <div class="row">
                  <a href="#" data-role="activity-box-link">
                      <div class="col-lg-12 p-0">
                          <!-- activity Image, Name & Subtitle (everyone) -->
                          <div class="activity-box-icon-container">
                              <img class="activity-box-icon" src="/images/NonCertifiante.png">
                          </div>
                          <div class="activity-item-title" role="heading" aria-level="2" >
                              Non-certifiante
                          </div>
                      </div>
                  </a>
                  <div class="activity-listing-subtitle text-left"> <ul style="padding-left: 20%;" class="mt-3 list-box">
                    <li>5 jours de formation</li>
                    <li>Délivrance d’un certificat de réussite</li>
                  </ul></div>
              </div>
          </div>
          </div>
</div>


<div class="mt-5">
  <span class=" title-color-primary h3">Sensibilisation à la sécurité de l’information</span>
<p class="mt-1 text-left container text-big">La sensibilisation à la cybersécurité consiste à changer les habitudes de vos collaborateurs quant aux bonnes pratiques a adopté dans le cadre leurs missions. Il est important de construire et de maintenir une culture de la sécurité de l’information au sein de votre organisation.
  C’est pourquoi, SECURELABS vous propose des sessions de sensibilisation ciblées sur des thématiques bien précises et conformes à vos besoins.</p>
</div>
<div class="row mt-5">
  <div class="col-12 col-md-10 mx-auto">
    <div class="nav-align-top mb-4">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <button type="button" class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#Partie2Obj" aria-controls="Partie2Obj" aria-selected="true">
            <img class=" rounded" src="/images/Objectif2.png" height="30" width="30" alt="">
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#Partie2Prog" aria-controls="Partie2Prog" aria-selected="false">
            <img class=" rounded" src="/images/Programme.jpg" height="30" width="30" alt="">
          </button>
        </li>
      </ul>
      <div class="tab-content mt-3">
        <div class="tab-pane fade active show text-left" id="Partie2Obj" role="tabpanel">
          <ul class="list-box">
            <li>Diffuser à l’ensemble de vos collaborateurs des informations relatives à la sécurité des systèmes d’information.</li>
            <li>Changer les habitudes de tout un chacun dans la sécurisation de l’information.</li>
          </ul>
        </div>
        <div class="tab-pane fade text-left" id="Partie2Prog" role="tabpanel">
          <h5 class="text-info text-start p-3 ">Gouvernance de la sécurité de l’information</h5>
        
        <ul class="mt-1 list-box">
          <li>Présentation en fonction des thématiques étudiées (mot de passe, divulgation, bureau propre, bonnes pratiques de sécurité,etc..).</li>
          <li>Audit de maturité.</li>
          <li>Objectifs de sécurité de l’organisme.</li>
          <li>Directives internes et Réglementations applicables.</li>
          <li>Rôles et Responsabilités</li>
          <li>Gestion des risques.</li>
          <li>Recommandations.</li>
        </ul>
        </div>
      </div>
    </div>
  </div>
</div>
    </main>
</template>
<script>
import Intro from '@/components/Intro'
export default {
    components:{
        Intro
    }
    
}
</script>
<style lang="">
    
</style>
