<template lang="">

    <Intro bgUrl="background-image: url('images/cyber/analyseRisques.jpg');">
             <!-- we will get this using -->
            <div class="title">
                <h3>Par Securelabs</h3>
            </div>
            <div class="content">
                <h4>Analyse de risques cybersécurité</h4>
            </div>
            <div class="action">
                <a href="#main-page">découvrez maintenant</a>
            </div>
    </Intro>
    <main>
        <div id="main-page" class="page-content py-0 mb-0">
                    <div id="first" class="container text-left mt-6" >
                        <div class="heading pt-4">
                            <h3 class="heading-title text-left title-color-primary">Analyse de risques cybersécurité reposant sur la méthodologie EBIOS RM de l’ANSSI</h3>
                            <div class="activity-listing-subtitle">
                                <div style="text-align: left;">
                                    La méthodologie utilisée par SECURELABS pour la réalisation d’analyse de risques cybersécurité s’appuie sur la méthodologie EBIOS-RISK MANAGER (2018) éditée par l’ANSSI et sur la norme ISO 27005.
                                    EBIOS RM permet d’apprécier les risques numériques et d’identifier les mesures de sécurité à mettre en œuvre pour les maîtriser. Elle permet aussi de valider le niveau de risque acceptable et de s’inscrire à plus long terme dans une démarche d’amélioration continue. Enfin, cette méthode permet de faire émerger les ressources et arguments utiles à la communication et à la prise de décision au sein de l’organisation et vis-à-vis de ses partenaires.
                                
                                </div>
                                <div class="heading-title text-left mt-4 title-color-primary">
                                    La méthode EBIOS RM peut être utilisée à plusieurs fins :
                                </div>
                                <ul class="list-box">
                                    <li>Mettre en place ou renforcer un processus de management du risque numérique au sein d’une organisation .</li>
                                    <li>Apprécier et traiter les risques relatifs à un projet numérique, notamment dans l’objectif d’une homologation de sécurité .</li>
                                    <li>Définir le niveau de sécurité à atteindre pour un produit ou un service selon ses cas d’usage envisagés et les risques à contrer, dans la perspective d’une certification ou d’un agrément par exemple.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div class="pb-4 mb-0 container" style="background-image: url('images/methodologie.jpg');width: 100%;">
                        <div class="heading pt-4 pb-2 mt-0 col-10 mx-auto">
            			    <h3 class="heading-title title-color-primary" >SECURELABS s’inspire donc de cette méthodologie pour analyser les risques cyber<u> via deux grandes étapes :</u>   </h3>
                            
                            <div class="activity-listing-subtitle">
                                <li class="heading-title text-left mt-4" style="color: #003679;text-align: left;">
                                    Identifier et définir le niveau de sécurité à atteindre
                                </li>
                                <div class="activity-box-icon-container mt-5 mb-5">
                                    <img class="activity-box-icon" src="images/cyber/analyseRisque/1.png">
                                </div>
                                <div class="mt-4" style="text-align: left;">
                                    <strong>
                                        L’analyse des risques cyber est utilisée pour plusieurs objectifs et permet notamment : 
                                    </strong>
                                </div>
                                <ul class="list-box">
                                    <li>De créer de la valeur ajoutée et de renforcer la gestion des risques .</li>
                                    <li>De traiter l’incertitude et de se prémunir contre les menaces toujours plus innovantes .</li>
                                    <li>De faciliter l’amélioration continue de l’organisme . </li>
                                </ul>
                                <div class="mt-4" style="text-align: left;">
                                    <strong>
                                        Il conviendra ensuite, via la méthode EBIOS RM, de définir le niveau de sécurité à atteindre notamment : 
                                    </strong>
                                </div>
                                <ul class="list-box">
                                    <li>Par les exigences de sécurité et de conformité issus des référentiels de sécurité, des lois et des règlements .</li>
                                    <li>Par la construction de scénarios de risques et par l’étude de l’impact et de la vraisemblance liés à ces scénarios .</li>
                                </ul>
                                <img class="mt-4 pt-4" alt="Icon made by Phatplus from www.flaticon.com" src="images/AnalyseDesRisquesSchema.jpg">

                            </div>
                        </div>
                        <div class="heading pt-2 pb-2 mt-0 col-10 mx-auto">
                            <li class="heading-title text-left mt-2" style="color: #003679;text-align: left;">
                                Définir une stratégie de traitement du risque
                            </li>
                            <div class="activity-box-icon-container mt-5 mb-5">
                                <img class="activity-box-icon" alt="Icon made by Freepik from www.flaticon.com" src="images/cyber/analyseRisque/2.png">
                            </div>
                            <div class="activity-listing-subtitle  text-left">
                                Après toute la démarche de caractérisation et d’identification des risques liés à l’activité, il s’agira d’établir une synthèse des risques étudiés afin d’établir une stratégie de traitement des risques. Un plan de traitement des risques sera élaboré afin de décrire les mesures de sécurité d’amélioration continue ainsi que les risques résiduels existants. 
                                Pour la réalisation de cette approche d’analyse de risque, SECURELABS prendra en considération les audits de sécurité préalable, la réalité opérationnelle ainsi que tous les process en place au sein de l’organisation.                            
                            </div>
                        </div>
                    </div>                    
            </div><!-- End .page-content -->
    </main>
</template>
<script>
import Intro from '@/components/Intro'
export default {
    components:{
        Intro
    }
    
}
</script>
<style lang="">
    
</style>
