<template lang="">

    <Intro bgUrl="background-image: url('images/cyber/politiqueSecurity.jpg');">
             <!-- we will get this using -->
            <div class="title">
                <h3>Par Securelabs</h3>
            </div>
            <div class="content">
                <h4> Politique de sécurité </h4>
            </div>
            <div class="action">
                <a href="#main-page">découvrez maintenant</a>
            </div>
        
    </Intro>
    <main>
        <div id="main-page" class="page-content d-flex align-items-center" style="min-height: 90vh; background-image: url('images/methodologie.jpg');">
                <div  class="col-10 mx-auto" >
                    <h3 class="mb-5 title-color-primary" style="font-weight: 600;">SECURELABS vous accompagne dans la rédaction de votre Politique de sécurité des systèmes d’information (PSSI).  </h3>
                    <p class="text-big">
                        La Politique de Sécurité des Systèmes d’Information (PSSI) est le document traduisant la stratégie de la Haute Direction, en matière de sécurité de l’information. Elle vient fixer les orientations de sécurité de la Haute Direction et fonde les bases de gouvernance de l’organisation tout en assurant le suivi d’actions de pilotage, des revues régulières et des ajustements basés sur le principe d’amélioration continue.
                        <br><br>
                        L’élaboration d’une PSSI doit se faire en tenant compte du contexte de la société, de ses enjeux, des risques de sécurité, de son cadre législatif et réglementaire, de la maturité de l’organisation et de la taille du système d’information d’information.
                        <br><br>
                        Les objectifs et les règles de sécurité, consacrés dans la PSSI, sont énoncés à partir de ceux définis par la norme ISO27002:2013 en tant que référentiel et guide de bonnes pratiques reconnu sur l’organisation de la sécurité.                                 
                    </p>                        
                </div>
            </div><!-- End .page-content -->
    </main>
</template>
<script>
import Intro from '@/components/Intro'
export default {
    components:{
        Intro
    },
    
    
}
</script>
<style lang="">
    
</style>
